import React from 'react';
import { Grid, Typography, Button, Container } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import hero2 from '../../../assets/images/hero2.jpg';
import hero3 from '../../../assets/images/hero3.jpg';
import hero4 from '../../../assets/images/hero4.jpg';

const HeroSection: React.FC = () => {
  return (
    <Container maxWidth='xl'>
    <Grid container spacing={2} >
      {/* Carousel for images */}
      <Grid item xs={12} sm={6}>
        <Carousel showThumbs={false} infiniteLoop autoPlay>
          <div>
            <img src={hero2} alt="Image 1" />
          </div>
          <div>
            <img src={hero3} alt="Image 2" />
          </div>
          <div>
            <img src={hero4} alt="Image 3" />
          </div>
          
          {/* Add more images as needed */}
        </Carousel>
      </Grid>
      {/* Text content and buttons */}
      <Grid item xs={12} sm={6}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item sx={{marginTop:'30px'}}>
            <Typography variant="h3" align="center" gutterBottom sx={{fontFamily:'Poppins'}}>
              Bienvenue à Ô SAVEUR
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center" gutterBottom sx={{fontFamily:'Poppins', textAlign:'justify', lineHeight:'35px'}}>
            Chez Ô SAVEUR, nous sommes fiers de vous offrir une expérience culinaire unique. Notre équipe dévouée travaille sans relâche pour vous apporter les saveurs les plus délicieuses de notre cuisine. Nous nous engageons à vous fournir des plats frais, préparés avec soin et amour.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center" paragraph sx={{fontFamily:'Poppins', textAlign:'justify', lineHeight:'35px'}}>
            Que vous choisissiez de vous faire livrer ou de venir chercher votre commande, nous sommes là pour vous offrir un repas mémorable. Découvrez notre menu et laissez-vous tenter par nos délices culinaires. Merci de nous avoir choisis pour votre prochain repas!
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button variant="contained" color="primary" sx={{fontFamily:'Poppins', height:'45px', borderRadius:'3px',background: '#FF0088'}}>
                Livraison
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" sx={{fontFamily:'Poppins', height:'45px', borderRadius:'3px',background: '#FF0088'}}>
                À emporter
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Container>
  );
};

export default HeroSection;
