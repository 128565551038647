import React from 'react';
import { Container, Typography, Tab, Tabs, Box, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { styled } from '@mui/system';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';


  // Données pour les vins
import Chardonnay from '../../../assets/images/Chardonnay.jpg';
import Cabernet from '../../../assets/images/Cabernet.jpg';
import Sauvignon from '../../../assets/images/Sauvignon.jpg';
import Merlot from '../../../assets/images/Merlot.jpg';
import Pinot  from '../../../assets/images/pinot.jpg';
import Rosé  from '../../../assets/images/RosePétillant.jpg';
  // Données pour les vins
 
    // Données pour les bières

import IPA from '../../../assets/images/ipa.jpg';
import Stout  from '../../../assets/images/StoutCrémeuse.jpg';
import Blonde from '../../../assets/images/BlondeLégère.jpg';
import Pale from '../../../assets/images/PaleAleAméricaine.jpg';
import Wheat from '../../../assets/images/Wheat Beer Rafraîchissante.jpg';
import Saison from '../../../assets/images/Saison Épicée.jpg';

  // Données pour les bières


 // Données pour les cocktails
 import cocktail from '../../../assets/images/coktail.jpg';
 import mojito from '../../../assets/images/mojito.jpg';
 import martini from '../../../assets/images/martini.jpg';
 import pina from '../../../assets/images/pina.jpg';
 import margarita from '../../../assets/images/margarita.jpg';
 import diakiri from '../../../assets/images/diakiri.jpg';


 // Données pour les cocktails

const Bar: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  // Données pour les cocktails
  const cocktails = [
    { id: 1, image: cocktail, name: "Cocktail Tropical", description: "Un mélange exotique de fruits frais avec une touche de rhum, parfait pour se détendre sur la plage.", price: "6000 FCFA", rating: 4.5 },
    { id: 2, image: mojito, name: "Mojito Frais", description: "Rafraîchissant et revigorant, ce cocktail classique à la menthe est idéal pour une chaude journée d'été.", price: "5500 FCFA", rating: 4.2 },
    { id: 3, image: martini, name: "Martini Royal", description: "Un élégant mélange de gin et de vermouth, garni d'une olive, pour une expérience raffinée.", price: "7000 FCFA", rating: 4.8 },
    { id: 4, image: pina, name: "Pina Colada Crémeuse", description: "Une concoction crémeuse de rhum, d'ananas et de noix de coco, transportant vos papilles dans les tropiques.", price: "6500 FCFA", rating: 4.1 },
    { id: 5, image: margarita, name: "Margarita Citronnée", description: "Un cocktail acidulé et rafraîchissant à base de tequila, de jus de citron vert et de triple sec.", price: "6000 FCFA", rating: 4.6 },
    { id: 6, image: diakiri, name: "Daiquiri aux Fraises", description: "Un cocktail sucré et fruité, parfait pour les amateurs de fraises et de rhum blanc.", price: "6500 FCFA", rating: 4.4 },
  ];

  // Données pour les vins
  const wines = [
    { id: 1, image: Chardonnay, name: "Chardonnay Doré", description: "Un vin blanc riche et onctueux aux notes de fruits tropicaux et de vanille, idéal en accompagnement de fruits de mer.", price: "12000 FCFA", rating: 4.7 },
    { id: 2, image: Cabernet, name: "Cabernet Sauvignon Élégant", description: "Un vin rouge corsé avec des arômes de cassis et de réglisse, parfait pour accompagner un steak juteux.", price: "15000 FCFA", rating: 4.9 },
    { id: 3, image: Sauvignon, name: "Sauvignon Blanc Rafraîchissant", description: "Un vin blanc vif et rafraîchissant avec des notes d'agrumes et d'herbes fraîches, idéal pour une soirée d'été.", price: "11000 FCFA", rating: 4.5 },
    { id: 4, image: Merlot, name: "Merlot Velouté", description: "Un vin rouge doux et velouté avec des saveurs de cerise et de chocolat, parfait pour une soirée relaxante.", price: "13000 FCFA", rating: 4.3 },
    { id: 5, image: Pinot , name: "Pinot Noir Délicat", description: "Un vin rouge léger et délicat avec des arômes de fraises et de champignons, parfait pour accompagner une cuisine légère.", price: "12500 FCFA", rating: 4.8 },
    { id: 6, image: Rosé, name: "Rosé Pétillant", description: "Un vin rosé pétillant avec des notes de fraises et de fleurs, idéal pour célébrer une occasion spéciale.", price: "14000 FCFA", rating: 4.6 },
  ];

  // Données pour les bières
  const beers = [
    { id: 1, image: IPA, name: "IPA Fruité", description: "Une bière artisanale brassée avec des houblons aromatiques, offrant des notes d'agrumes et de fruits tropicaux.", price: "4500 FCFA", rating: 4.0 },
    { id: 2, image: Stout, name: "Stout Crémeuse", description: "Une bière noire riche et crémeuse avec des saveurs de café et de chocolat, parfaite pour les amateurs de bières foncées.", price: "5000 FCFA", rating: 4.3 },
    { id: 3, image: Blonde, name: "Blonde Légère", description: "Une bière blonde légère et rafraîchissante, parfaite pour une dégustation décontractée entre amis.", price: "4000 FCFA", rating: 4.2 },
    { id: 4, image: Pale, name: "Pale Ale Américaine", description: "Une bière ambrée avec des arômes de houblon floral et une amertume équilibrée, idéale pour accompagner des plats épicés.", price: "4200 FCFA", rating: 4.1 },
    { id: 5, image: Wheat, name: "Wheat Beer Rafraîchissante", description: "Une bière de blé légère et rafraîchissante avec des notes d'agrumes et d'épices, parfaite pour une journée ensoleillée.", price: "4300 FCFA", rating: 4.4 },
    { id: 6, image: Saison, name: "Saison Épicée", description: "Une bière de saison avec des notes épicées de poivre et de coriandre, offrant une expérience de dégustation complexe.", price: "4800 FCFA", rating: 4.5 },
  ];

  // Composant styled pour les étoiles dorées
  const GoldStarIcon = styled(StarIcon)({
    color: 'gold',
  });

  // Fonction pour afficher les étoiles en fonction de la note
  const renderStars = (rating: number) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(<GoldStarIcon key={i} />);
      } else {
        stars.push(<StarBorderIcon key={i} />);
      }
    }
    return stars;
  };

  return (
    <Container maxWidth='xl' sx={{ marginTop: '45px' }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Poppins' }}>
        Bar
      </Typography>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        variant="fullWidth"
      >
        <Tab sx={{ fontFamily: 'Poppins' }} label="Cocktails" />
        <Tab sx={{ fontFamily: 'Poppins' }} label="Vins" />
        <Tab sx={{ fontFamily: 'Poppins' }} label="Bières" />
      </Tabs>
      {selectedTab === 0 && (
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={4}>
            {cocktails.map(cocktail => (
              <Grid item xs={12} sm={6} md={4} key={cocktail.id}>
                <Card>
                  <CardMedia component="img"  height="280" 
  sx={{ 
    objectFit: 'fill', 
    objectPosition: 'center' // Assure que l'image est centrée horizontalement et verticalement
  }}  image={cocktail.image} alt={cocktail.name} />
                  <CardContent>
                    <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' }}>{cocktail.name}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins' }}>{cocktail.description}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins' }}>Prix: {cocktail.price}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins' }}>Note: {renderStars(cocktail.rating)}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {selectedTab === 1 && (
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={4}>
            {wines.map(wine => (
              <Grid item xs={12} sm={6} md={4} key={wine.id}>
                <Card>
                  <CardMedia component="img" height="300" sx={{ objectFit:'contain'}} image={wine.image} alt={wine.name} />
                  <CardContent>
                    <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' }}>{wine.name}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins' }}>{wine.description}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins' }}>Prix: {wine.price}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins' }}>Note: {renderStars(wine.rating)}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {selectedTab === 2 && (
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={4}>
            {beers.map(beer => (
              <Grid item xs={12} sm={6} md={4} key={beer.id}>
                <Card>
                  <CardMedia component="img" height="300" sx={{ objectFit:'contain'}} image={beer.image} alt={beer.name} />
                  <CardContent>
                    <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' }}>{beer.name}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins' }}>{beer.description}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins' }}>Prix: {beer.price}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins' }}>Note: {renderStars(beer.rating)}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Container>
  );
};

export default Bar;
