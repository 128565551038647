import React from 'react'
import { Grid, Typography,Link, Box } from '@mui/material';

const TermsConditions = () => {
  return (
    <Box>
      
    <Grid container spacing={5} textAlign={'center'} >
    <Grid item xs={12} >
      <Typography sx={{fontFamily:'Poppins', textDecoration:'none', color:'#fff'}}>Ô SAVEUR | Termes & Conditions | Politique de confidentialité<Link sx={{fontFamily:'Poppins', textDecoration:'none', color:'#fff'}}></Link></Typography>
    </Grid>
    </Grid>
   
  </Box>
  )
}

export default TermsConditions
