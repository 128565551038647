import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  submenu: {
    paddingLeft: 4, // Ajustez l'espacement selon vos besoins
  },
}));

const TopMenuDrawer: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState('');

  const classes = useStyles();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleItemClick = (menu: string) => {
    if (expandedMenu === menu) {
      setExpandedMenu('');
    } else {
      setExpandedMenu(menu);
    }
  };

  return (
    <>
      {/* Icone de menu hamburger pour les petits écrans */}
      <IconButton
        size="large"
        edge="end"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      {/* Menu déroulant pour les petits écrans */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <List>
          <ListItem button onClick={() => handleItemClick('restaurant')}>
            <ListItemText primary="Restaurant" />
            {expandedMenu === 'restaurant' && <ExpandMoreIcon />}
          </ListItem>
          {expandedMenu === 'restaurant' && (
            <>
              <ListItem button onClick={toggleDrawer(false)}>
                <ListItemText primary="Menu 1" className={classes.submenu} />
              </ListItem>
              <ListItem button onClick={toggleDrawer(false)}>
                <ListItemText primary="Menu 2" className={classes.submenu} />
              </ListItem>
              <ListItem button onClick={toggleDrawer(false)}>
                <ListItemText primary="Menu 3" className={classes.submenu} />
              </ListItem>
            </>
          )}
          <ListItem button onClick={() => handleItemClick('snackbar')}>
            <ListItemText primary="Snack Bar" />
            {expandedMenu === 'snackbar' && <ExpandMoreIcon />}
          </ListItem>
          {expandedMenu === 'snackbar' && (
            <>
              <ListItem button onClick={toggleDrawer(false)}>
                <ListItemText primary="Snack 1" className={classes.submenu} />
              </ListItem>
              <ListItem button onClick={toggleDrawer(false)}>
                <ListItemText primary="Snack 2" className={classes.submenu} />
              </ListItem>
              <ListItem button onClick={toggleDrawer(false)}>
                <ListItemText primary="Snack 3" className={classes.submenu} />
              </ListItem>
            </>
          )}
          <ListItem button onClick={() => handleItemClick('services')}>
            <ListItemText primary="Prestations de Services" />
            {expandedMenu === 'services' && <ExpandMoreIcon />}
          </ListItem>
          {expandedMenu === 'services' && (
            <>
              <ListItem button onClick={toggleDrawer(false)}>
                <ListItemText primary="Service 1" className={classes.submenu} />
              </ListItem>
              <ListItem button onClick={toggleDrawer(false)}>
                <ListItemText primary="Service 2" className={classes.submenu} />
              </ListItem>
              <ListItem button onClick={toggleDrawer(false)}>
                <ListItemText primary="Service 3" className={classes.submenu} />
              </ListItem>
            </>
          )}
          <ListItem button onClick={toggleDrawer(false)}>
            <ListItemText primary="Accueil" />
          </ListItem>
          <ListItem button onClick={toggleDrawer(false)}>
            <ListItemText primary="A Propos" />
          </ListItem>
          <ListItem button onClick={toggleDrawer(false)}>
            <ListItemText primary="Nous contacter" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default TopMenuDrawer;
