import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Rating } from '@mui/material';

const Testimonials: React.FC = () => {
  // Exemple de données pour les témoignages
  const testimonials = [
    { id: 1, name: 'John Doe', rating: 5, comment: 'Excellent service et plats délicieux!' },
    { id: 2, name: 'Jane Smith', rating: 4, comment: 'Une expérience formidable, je recommande vivement.' },
    { id: 3, name: 'Alice Johnson', rating: 5, comment: 'Le meilleur restaurant de la ville, sans aucun doute.' },
    // Ajoutez plus de témoignages selon vos besoins
  ];

  return (
    <Container maxWidth='xl' sx={{ marginTop: '45px' }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Poppins' }}>
        Témoignages de nos clients
      </Typography>
      <Grid container spacing={4}>
        {testimonials.map(testimonial => (
          <Grid item xs={12} sm={6} md={4} key={testimonial.id}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' }}>
                  {testimonial.name}
                </Typography>
                <Rating value={testimonial.rating} readOnly />
                <Typography variant="body1" sx={{ fontFamily: 'Poppins' }}>
                  {testimonial.comment}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Testimonials;
