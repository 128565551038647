import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TopMenuNavigation from './components/navigation/TopMenuNavigation';
import Home from '../src/features/home/Home';
import Footer from './components/navigation/Footer';

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <TopMenuNavigation />
      <Routes>
        {/* Définissez vos routes ici */}
        {/* Par exemple : */}
        <Route path="/" element={<Home/>} /> 
      </Routes>
      <Footer/>
    </Router>
  )
}

export default AppRoutes;
