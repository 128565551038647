import React from 'react';
import { Container, Typography, Grid, Link, TextField, Button } from '@mui/material';
import Copyright from './Copyright';
import TermsConditions from './TermsConditions';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer: React.FC = () => {
  return (
    <footer style={{ backgroundColor: '#FF0088', padding: '50px 0', marginTop: '50px' }}>
      <Container maxWidth='xl'>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' , color:'white'}}>
              LIENS UTILES
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins', color:'white' }}>
              <Link href="/menu" color="inherit">Menu</Link>
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins', color:'white' }}>
              <Link href="/reservation" color="inherit">Réservation</Link>
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins', color:'white' }}>
              <Link href="/contact" color="inherit">Contact</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' , color:'white'}}>
              CONTACTEZ-NOUS
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins' , color:'white'}}>
              Téléphone: +237 699723793 / 233 47 83 56
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins', color:'white' }}>
              Email: osaveur@yahoo.fr
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins', color:'white' }}>
            Adresse : Sis en face parcours vita-Bonamoussa, Douala, Cameroun
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
          <Grid >
          <Typography variant='h6' sx={{fontFamily:'Poppins', color:'white'}}>NOTRE NEWSLETTERS</Typography>
          </Grid>
          <br/>
          <Grid >
          <Typography variant='body1'  sx={{fontFamily:'Poppins', fontSize:'18px', lineHeight:'33px', color:'white'}}>Abonnez-vous à notre newsletter et recevez toute notre actualité</Typography>
          </Grid>
          <br/>
          <Grid >
          <TextField label='Email '  variant='outlined' sx={{fontFamily:'Poppins', width:'100%', color:'white'}}/>
          </Grid>
       
          <Button variant='contained' className='btn' sx={{fontFamily:'Poppins', width:'100%', height:'50px', color:'#fff', marginTop:'10px', background:'#333'}}>INSCRIVEZ-VOUS</Button>

          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins', color:'white' }}>
            RÉSEAUX SOCIAUX
            </Typography>
            <Grid container sx={{marginTop:'10px'}}>
            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins' }}>
            <Link href="https://twitter.com/restaurant" target="_blank" color="inherit">
          <TwitterIcon sx={{ fontSize: 27, color: 'white', marginRight: '10px' }} /></Link>
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins' }}>
            <Link href="https://www.facebook.com/restaurant" target="_blank" color="inherit">
          <FacebookIcon sx={{ fontSize: 27, color: 'white', marginRight: '10px' }} />
        </Link>
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins' }}>
            <Link href="https://www.instagram.com/restaurant" target="_blank" color="inherit">
          <InstagramIcon sx={{ fontSize: 27, color: 'white' }} />
        </Link>
            </Typography>
            
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Copyright />
            <TermsConditions />
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
