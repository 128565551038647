import React from 'react';
import { Grid, Typography, Card, CardContent, CardMedia, Tab, Tabs, Container } from '@mui/material';
import { Rating } from '@mui/material';

// desserts
import begneits from '../../../assets/images/beignets.jpg';
import papaye from '../../../assets/images/papaye.jpg';
import pasteque from '../../../assets/images/pasteque.jpg';
import Koeksister from '../../../assets/images/Koeksister.jpg';
import tiramisu from '../../../assets/images/tiramisu.jpg';
import dabo from '../../../assets/images/dabo.jpg';
// desserts

// entrées
import sambusa from '../../../assets/images/sambusa.jpg';
import ndole from '../../../assets/images/ndole.jpg';
import fataya from '../../../assets/images/fataya.jpg';
import pastilla from '../../../assets/images/pastilla.jpg';
import yassa from '../../../assets/images/yassa.jpg';
import chin from '../../../assets/images/chin.jpg';
// fin entrées

//plat
import couscous from '../../../assets/images/couscous.jpg';
import pouletY from '../../../assets/images/pouletY.jpg';
import mafe from '../../../assets/images/mafe.jpg';
import Thieboudienne from '../../../assets/images/Thiéboudienne.jpg';
import Chakalaka from '../../../assets/images/Chakalaka.jpg';
import Pilau from '../../../assets/images/Pilau.jpg';

// fin plat

const Menus: React.FC = () => {
  // Exemple de données pour les plats
  const menuItems = [
    { category: 'Entrée', name: 'Sambusa', description: 'Beignets croustillants farcis de viande épicée ou de légumes, une entrée délicieusement savoureuse.', price: '1500 XAF', image: sambusa },
    { category: 'Entrée', name: 'Ndolé', description: 'Un plat camerounais composé de feuilles de ndolé (bitterleaf) et de diverses viandes, souvent accompagné de plantains.', price: '2000 XAF', image: ndole },
    { category: 'Entrée', name: 'Fataya', description: 'Petits chaussons farcis de thon épicé, un régal sénégalais parfait pour ouvrir l\'appétit.', price: '1800 XAF', image: fataya },
    { category: 'Entrée', name: 'Pastilla', description: "Un plat marocain traditionnel composé de pâte feuilletée farcie de viande, d'amandes, d'œufs et d'épices.", price: '1900 XAF', image: pastilla },
    { category: 'Entrée', name: 'Yassa', description: 'Poulet mariné dans une sauce citronnée et moutardée, un classique sénégalais incontournable.', price: '1700 XAF', image: yassa },
    { category: 'Entrée', name: 'Chin Chin', description: "Petits gâteaux croquants originaires d'Afrique de l'Ouest, parfaits pour grignoter.", price: '1600 XAF', image: chin },
    { category: 'Plat principal', name: 'Couscous', description: 'Un plat berbère traditionnel à base de semoule de blé dur servie avec une variété de légumes et de viandes ou de poissons.', price: '3500 XAF', image: couscous },
    { category: 'Plat principal', name: 'Poulet Yassa', description: 'Poulet mariné dans une sauce à base d\'oignons, de jus de citron et de moutarde, accompagné de riz ou de mil.', price: '4000 XAF', image: pouletY },
    { category: 'Plat principal', name: 'Mafé', description: 'Un ragoût sénégalais épais à base de viande (généralement du bœuf ou du poulet) et de légumes dans une sauce aux arachides.', price: '4200 XAF', image: mafe },
    { category: 'Plat principal', name: 'Thiéboudienne', description: 'Plat national du Sénégal, du riz cuit dans une sauce tomate épicée avec du poisson et des légumes.', price: '3900 XAF', image: Thieboudienne },
    { category: 'Plat principal', name: 'Chakalaka', description: 'Un plat sud-africain à base de légumes épicés, souvent servi avec du pap et de la viande grillée.', price: '3800 XAF', image: Chakalaka },
    { category: 'Plat principal', name: 'Pilau', description: "Riz épicé cuit avec des viandes, des légumes et des épices, un plat populaire en Afrique de l'Est.", price: '4100 XAF', image: Pilau},
    { category: 'Dessert', name: 'Beignets de Banane Plantain', description: 'Bananes plantain frites en beignets sucrés, parfaits pour une fin de repas douce et satisfaisante.', price: '2500 XAF', image: begneits },
    { category: 'Dessert', name: 'Sorbet à la Papaye', description: 'Papaye mûre glacée au sirop de sucre, une délicieuse gâterie rafraîchissante.', price: '2200 XAF', image: papaye },
    { category: 'Dessert', name: 'Pastèque au Piment', description: 'Tranches de pastèque fraîche saupoudrées de piment en poudre, un dessert simple et rafraîchissant avec une touche épicée.', price: '2000 XAF', image: pasteque },
    { category: 'Dessert', name: 'Koeksister', description: 'Pâtisserie sud-africaine trempée dans du sirop sucré, souvent en forme de tresse.', price: '2400 XAF', image: Koeksister },
    { category: 'Dessert', name: 'Tiramisu Africain', description: 'Une version africaine du classique italien, avec des saveurs locales et une touche de café.', price: '2600 XAF', image: tiramisu},
    { category: 'Dessert', name: 'Dabo Kolo', description: 'Petits gâteaux croquants éthiopiens, souvent aromatisés avec des épices comme le cumin et le cardamome.', price: '2300 XAF', image: dabo },
  ];

  // Exemple de données pour les avis associés aux produits
  const productReviews = [
    { productName: 'Sambusa', rating: 4 },
    { productName: 'Ndolé', rating: 5 },
    { productName: 'Fataya', rating: 4 },
    { productName: 'Pastilla', rating: 5 },
    { productName: 'Yassa', rating: 4 },
    { productName: 'Chin Chin', rating: 3 },
    { productName: 'Couscous', rating: 5 },
    { productName: 'Poulet Yassa', rating: 4 },
    { productName: 'Mafé', rating: 3 },
    { productName: 'Thiéboudienne', rating: 4 },
    { productName: 'Chakalaka', rating: 5 },
    { productName: 'Pilau', rating: 4 },
    { productName: 'Beignets de Banane Plantain', rating: 5 },
    { productName: 'Papaye Glacée', rating: 4 },
    { productName: 'Pastèque au Piment', rating: 5 },
    { productName: 'Koeksister', rating: 4 },
    { productName: 'Tiramisu Africain', rating: 5 },
    { productName: 'Dabo Kolo', rating: 4 },
  ];

  // Filtrer les plats par catégorie
  const filterMenuItems = (category: string) => {
    return menuItems.filter(item => item.category === category);
  };

  // Récupérer la note moyenne pour un produit donné
  const getProductRating = (productName: string) => {
    const reviews = productReviews.filter(review => review.productName === productName);
    const totalRatings = reviews.reduce((sum, review) => sum + review.rating, 0);
    return totalRatings / reviews.length;
  };

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Container sx={{marginTop:'45px'}}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h4" align="center" gutterBottom sx={{fontFamily:'Poppins'}}> 
            Notre Menu Africain
          </Typography>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab sx={{fontFamily:'Poppins'}} label="Entrées" />
            <Tab sx={{fontFamily:'Poppins'}} label="Plats principaux" />
            <Tab sx={{fontFamily:'Poppins'}}label="Desserts" />
          </Tabs>
          <Typography sx={{marginTop:'7px'}}></Typography>
          {/* Affichage des plats selon la catégorie sélectionnée */}
          <Grid container spacing={4}>
            {filterMenuItems(selectedTab === 0 ? 'Entrée' : selectedTab === 1 ? 'Plat principal' : 'Dessert').map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardMedia component="img" height="250" sx={{ objectFit:'fill'}} image={item.image} alt={item.name}  />
                  <CardContent>
                    <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins' }}>
                      {item.name}
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ textAlign: 'justify',fontFamily: 'Poppins' }}>
                      {item.description}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" sx={{fontFamily:'Poppins'}}>
                      Prix: {item.price}
                    </Typography>
                    <Rating value={getProductRating(item.name)} readOnly />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Menus;
