import React from 'react'
import { Grid, Typography, Box } from '@mui/material';

const Copyright = () => {
  return (
    <Box>  
      <br/>
             
      <Grid container textAlign={'center'} sx={{marginTop:'40px'}}>
      <Grid item xs={12}  >
      <Typography sx={{marginTop:'10px'}}> <hr /></Typography>
        <Typography sx={{fontFamily:'Poppins',  color:'#fff'}}>© 2024 Ô SAVEUR. Tous droits réservés.</Typography>
      </Grid>
      </Grid>
      <Typography sx={{marginTop:'8px'}}></Typography>
     
    </Box>
  )
}

export default Copyright
