import { Box } from '@mui/material';
import React from 'react';
import HeroSection from './models/HeroSection';
import Menus from './models/Menus';
import SpecialPromo from './models/SpecialPromo';
import Reservation from './models/Reservation';
import Contact from './models/Contact';
import About from './models/About';
import SocialMedia from './models/SocialMedia';
import Partners from './models/Partners';
import Testimonials from './models/Testimonials';
import Bar from './models/Bar';
import Services from './models/Services';

const Home = () => {
  return (
    <Box>
        <HeroSection/>
        <About/>
        <Menus/>
        <Bar/>
        <Services/>
        <SpecialPromo/>
        <Testimonials/>
        <Reservation/>
        <Contact/>
    </Box>
  )
}

export default Home
