import React from 'react';
import { Grid, Typography, Card, CardContent, CardMedia, Tab, Tabs, Container } from '@mui/material';
import hero2 from '../../../assets/images/hero2.jpg';
import hero3 from '../../../assets/images/hero3.jpg';
import hero4 from '../../../assets/images/hero4.jpg';

const SpecialPromo: React.FC = () => {
  // Exemple de données pour les promotions spéciales
  const specialPromotions = [
    { category: 'Entrée', name: 'Salade de Fruits Frais', description: 'Dégustez une salade de fruits frais et savoureuse.', oldPrice: '6500 FCFA', newPrice: '5000 FCFA', image: hero3 },
    { category: 'Entrée', name: 'Assiette de Crudités', description: 'Savourez une assiette fraîche et colorée de crudités.', oldPrice: '6000 FCFA', newPrice: '4500 FCFA', image: hero2 },
    { category: 'Entrée', name: 'Soupe de Légumes', description: 'Réchauffez-vous avec notre soupe de légumes faite maison.', oldPrice: '5500 FCFA', newPrice: '4000 FCFA', image: hero4 },
    { category: 'Entrée', name: 'Bruschetta', description: 'Appréciez nos délicieuses bruschettas avec des ingrédients frais.', oldPrice: '7000 FCFA', newPrice: '5500 FCFA', image: hero3 },
    { category: 'Entrée', name: 'Pain à l\'Ail', description: 'Dégustez notre pain à l\'ail croustillant et savoureux.', oldPrice: '8000 FCFA', newPrice: '6500 FCFA', image: hero2 },
    { category: 'Entrée', name: 'Crevettes Grillées', description: 'Savourez nos crevettes grillées marinées avec des herbes fraîches.', oldPrice: '9000 FCFA', newPrice: '7500 FCFA', image: hero4 },

    { category: 'Plat principal', name: 'Poulet Rôti', description: 'Succombez à notre délicieux poulet rôti servi avec des légumes de saison.', oldPrice: '16000 FCFA', newPrice: '12000 FCFA', image: hero2 },
    { category: 'Plat principal', name: 'Filet de Poisson Grillé', description: 'Dégustez notre filet de poisson grillé avec une sauce aux herbes.', oldPrice: '18000 FCFA', newPrice: '14000 FCFA', image: hero3 },
    { category: 'Plat principal', name: 'Lasagnes', description: 'Régalez-vous avec nos lasagnes maison garnies de fromage fondant.', oldPrice: '15000 FCFA', newPrice: '11000 FCFA', image: hero4 },
    { category: 'Plat principal', name: 'Bœuf Bourguignon', description: 'Savourez notre bœuf bourguignon mijoté lentement pour une tendreté maximale.', oldPrice: '20000 FCFA', newPrice: '16000 FCFA', image: hero2 },
    { category: 'Plat principal', name: 'Couscous Royal', description: 'Dégustez notre couscous royal garni de viande, poulet et légumes.', oldPrice: '22000 FCFA', newPrice: '18000 FCFA', image: hero3 },
    { category: 'Plat principal', name: 'Riz Cantonais', description: 'Appréciez notre riz cantonais sauté avec des légumes et des crevettes.', oldPrice: '13000 FCFA', newPrice: '9000 FCFA', image: hero4 },

    { category: 'Dessert', name: 'Tarte aux Fruits', description: 'Faites-vous plaisir avec notre tarte aux fruits garnie de saison.', oldPrice: '10000 FCFA', newPrice: '8000 FCFA', image: hero4 },
    { category: 'Dessert', name: 'Mousse au Chocolat', description: 'Savourez notre mousse au chocolat légère et délicieuse.', oldPrice: '9000 FCFA', newPrice: '7000 FCFA', image: hero3 },
    { category: 'Dessert', name: 'Crème Brûlée', description: 'Délectez-vous avec notre crème brûlée caramélisée à la perfection.', oldPrice: '8000 FCFA', newPrice: '6000 FCFA', image: hero2 },
    { category: 'Dessert', name: 'Tiramisu', description: 'Régalez-vous avec notre tiramisu italien fait maison.', oldPrice: '7000 FCFA', newPrice: '5000 FCFA', image: hero4 },
    { category: 'Dessert', name: 'Fondant au Chocolat', description: 'Fondez de plaisir avec notre fondant au chocolat chaud et coulant.', oldPrice: '6000 FCFA', newPrice: '4000 FCFA', image: hero3 },
    { category: 'Dessert', name: 'Salade de Fruits Frais', description: 'Dégustez une salade de fruits frais et savoureuse.', oldPrice: '5000 FCFA', newPrice: '3000 FCFA', image: hero2 },
  ];

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  // Filtrer les promotions spéciales par catégorie
  const filterSpecialPromotions = (category: string) => {
    return specialPromotions.filter(promotion => promotion.category === category);
  };

  return (
    <Container sx={{marginTop:'45px'}}>
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h4" align="center" gutterBottom>
          Promotions Spéciales
        </Typography>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab sx={{fontFamily:'Poppins'}} label="Entrées" />
          <Tab sx={{fontFamily:'Poppins'}} label="Plats principaux" />
          <Tab sx={{fontFamily:'Poppins'}} label="Desserts" />
        </Tabs>
        <Typography sx={{marginTop:'7px'}}></Typography>
        <Grid container spacing={4}>
          {filterSpecialPromotions(selectedTab === 0 ? 'Entrée' : selectedTab === 1 ? 'Plat principal' : 'Dessert').map((promotion, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardMedia component="img" height='300' sx={{ objectFit: 'fill' }} image={promotion.image} alt={promotion.name} />
                <CardContent>
                  <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins' }}>
                    {promotion.name}
                  </Typography>
                  <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins' }}>
                    {promotion.description}
                  </Typography>
                  <Typography variant="body1" color="textSecondary" sx={{ textDecoration: 'line-through', fontFamily: 'Poppins' }}>
                    {promotion.oldPrice}
                  </Typography>
                  <Typography variant="body1" color="primary" sx={{ fontFamily: 'Poppins' }}>
                    Nouveau Prix: {promotion.newPrice}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
    </Container>
  );
};

export default SpecialPromo;
