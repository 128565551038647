import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';
import eventSpaceImage from '../../../assets/images/salle.jpg';
import cateringServiceImage from '../../../assets/images/traiteurs.jpg';
import cookingClassImage from '../../../assets/images/formations.jpg';

const Services: React.FC = () => {
  const services = [
    { id: 1, image: eventSpaceImage, title: "Location de Salles", description: "Offrez à vos invités un cadre élégant et adapté à tout type d'événement privé." },
    { id: 2, image: cateringServiceImage, title: "Service de Traiteur", description: "Faites appel à notre équipe de chefs professionnels pour des plats exceptionnels lors de vos événements spéciaux." },
    { id: 3, image: cookingClassImage, title: "Cours de Cuisine", description: "Apprenez des secrets de la cuisine avec nos cours dispensés par des chefs experts." },
    // Ajoutez d'autres services supplémentaires selon vos besoins
  ];

  return (
    <Container maxWidth='xl' sx={{ marginTop: '45px' }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Poppins' }}>
        Prestations de Services
      </Typography>
      <Grid container spacing={4}>
        {services.map(service => (
          <Grid item xs={12} md={4} key={service.id}>
            <Card>
              <CardMedia component="img" height='300' sx={{ objectFit: 'cover' }} image={service.image} alt={service.title} />
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins' }}>{service.title}</Typography>
                <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins' }}>{service.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Services;
