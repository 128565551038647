import React from 'react';
import { Grid, Typography, Box, Container } from '@mui/material';
import hero2 from '../../../assets/images/hero2.jpg';
import hero3 from '../../../assets/images/hero3.jpg';
import hero4 from '../../../assets/images/hero4.jpg';

const AboutSection: React.FC = () => {
  return (
    <Container maxWidth='xl' sx={{marginTop:'45px'}}>
      <Grid container spacing={4}>
        {/* A propos de nous */}
        <Grid item xs={12}>
          <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Poppins' }}> 
            A propos de nous
          </Typography>
        </Grid>
        {/* Notre Histoire */}
        <Grid item xs={12} md={6}>
          <Box p={2}>
            <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Poppins' }}>
              Notre Histoire
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins', fontSize:'20px', textAlign:'justify' }}>
              Ô Saveur est né de la passion pour la cuisine raffinée et l'art culinaire. Nous nous engageons à offrir à nos clients une expérience gastronomique unique, en mettant en valeur les saveurs authentiques et les ingrédients de la plus haute qualité.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Image pour illustrer Notre Histoire */}
          <img src={hero2} alt="Notre Histoire" style={{ maxWidth: '100%', height: 'auto' }} />
        </Grid>

        {/* Philosophie Culinaire */}
        <Grid item xs={12} md={6}>
          {/* Image pour illustrer la Philosophie Culinaire */}
          <img src={hero3} alt="Philosophie Culinaire" style={{ maxWidth: '100%', height: 'auto' }} />
        </Grid>

        
        <Grid item xs={12} md={6}>
          <Box p={2}>
            <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Poppins' }}>
              Notre Philosophie Culinaire
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins', fontSize:'20px', textAlign:'justify' }}>
              Notre philosophie culinaire repose sur la créativité, le respect des traditions et le souci du détail. Chaque plat est conçu avec soin pour offrir une explosion de saveurs et une expérience gustative inoubliable à nos clients.
            </Typography>
          </Box>
        </Grid>
        
        {/* Nos Valeurs */}
        <Grid item xs={12} md={6}>
          <Box p={2}>
            <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Poppins' }}>
              Nos Valeurs
            </Typography>
            <ul style={{ paddingLeft: '20px' }}>
              <li style={{ fontFamily: 'Poppins', fontSize:'20px', textAlign:'justify', marginBottom: '10px' }}>Qualité exceptionnelle des ingrédients</li>
              <li style={{ fontFamily: 'Poppins', fontSize:'20px', textAlign:'justify', marginBottom: '10px' }}>Service attentif et chaleureux</li>
              <li style={{ fontFamily: 'Poppins', fontSize:'20px', textAlign:'justify', marginBottom: '10px' }}>Innovation culinaire continue</li>
              <li style={{ fontFamily: 'Poppins', fontSize:'20px', textAlign:'justify', marginBottom: '10px' }}>Engagement envers la durabilité</li>
              <li style={{ fontFamily: 'Poppins', fontSize:'20px', textAlign:'justify', marginBottom: '10px' }}>Respect de la diversité culturelle</li>
            </ul>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Image pour illustrer Nos Valeurs */}
          <img src={hero4} alt="Nos Valeurs" style={{ maxWidth: '100%', height: 'auto' }} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutSection;
