import React from 'react'
import { Box } from '@mui/material'
import TopMenuInfos from './TopMenuInfos'
import TopMenu from './TopMenu'

const TopMenuNavigation = () => {
  return (
    <Box>
      <TopMenuInfos/>
      <TopMenu/>
    </Box>
  )
}

export default TopMenuNavigation
