import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, Container } from '@mui/material';

const Contact: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleContactSubmit = () => {
    // Logique pour gérer la soumission du formulaire de contact, par exemple, envoi à un backend, etc.
    console.log('Nom:', name);
    console.log('Email:', email);
    console.log('Message:', message);
    // Réinitialiser les champs après la soumission
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <Container maxWidth='xl' sx={{marginTop:'45px', background:'#F8F8F8'}}>
    <Grid container spacing={4} justifyContent="center" >
      <Grid item xs={12} md={6}>
        <Typography variant="h4" align="center" gutterBottom sx={{fontFamily:'Poppins'}}>
          Contactez-nous
        </Typography>
        <Typography variant="body1" paragraph sx={{fontFamily:'Poppins'}}>
        Pour toute demande d'information supplémentaire ou pour réserver votre table, n'hésitez pas à nous contacter. Notre équipe se fera un plaisir de vous aider.
        </Typography>
        <Typography variant="body1" paragraph sx={{fontFamily:'Poppins'}}>
          Adresse : Sis en face parcours vita-Bonamoussa, Douala, Cameroun
        </Typography>
        <Typography variant="body1" paragraph sx={{fontFamily:'Poppins'}}>
          Téléphone : +237 699 72 37 93 / 233 47 83 56
        </Typography>
        <Typography variant="body1" paragraph sx={{fontFamily:'Poppins'}}>
          Heures d'ouverture : Lun - Ven : 10h00 - 22h00, Sam - Dim : 12h00 - 23h00
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h5" align="center" gutterBottom sx={{fontFamily:'Poppins'}}> 
          Formulaire de Contact
        </Typography>
        <TextField
          fullWidth
          label="Nom"
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Message"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleContactSubmit} sx={{fontFamily:'Poppins', height:'45px' ,borderRadius:'3px',background: '#FF0088'}}>
          Envoyer
        </Button>
      </Grid>
    </Grid>
    </Container>
  );
};

export default Contact;
