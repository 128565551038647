import React, { useState } from 'react';
import { Grid, Typography, TextField, Button,Container } from '@mui/material';

const Reservation: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [numberOfPeople, setNumberOfPeople] = useState('');

  const handleReservationSubmit = () => {
    // Logique pour gérer la soumission de la réservation, par exemple, envoi à un backend, etc.
    console.log('Nom:', firstName);
    console.log('Prénom:', lastName);
    console.log('Email:', email);
    console.log('Date:', date);
    console.log('Time:', time);
    console.log('Number of People:', numberOfPeople);
    // Réinitialiser les champs après la soumission
    setFirstName('');
    setLastName('');
    setEmail('');
    setDate('');
    setTime('');
    setNumberOfPeople('');
  };

  return (
    <Container maxWidth='xl' sx={{marginTop:'45px'}}>
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h4" align="center" gutterBottom sx={{fontFamily:'Poppins'}}>
          Réserver une Table
        </Typography>
        <Typography sx={{marginTop:'7px'}}></Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
            sx={{fontFamily:'Poppins'}}
              fullWidth
              label="Nom"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              sx={{fontFamily:'Poppins'}}
              label="Prénom"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              sx={{fontFamily:'Poppins'}}
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
            sx={{fontFamily:'Poppins'}}
              fullWidth
              label="Date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
            sx={{fontFamily:'Poppins'}}
              fullWidth
              label="Heure"
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
            sx={{fontFamily:'Poppins'}}
              fullWidth
              label="Nombre de personnes"
              type="number"
              value={numberOfPeople}
              onChange={(e) => setNumberOfPeople(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleReservationSubmit} sx={{fontFamily:'Poppins', height:'45px' ,borderRadius:'3px',background: '#FF0088'}}>
              Réserver
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Container>
  );
};

export default Reservation;
