import React from 'react'
import {AppBar, Toolbar,Typography, Grid } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';


const TopMenuInfos = () => {
  return (
    <AppBar position="static" sx={{ background: '#FF0088', height: '45px' }}>
    <Toolbar>
      <Grid container spacing={2} alignItems="center">
        {/* Numéro de téléphone */}
        <Grid item xs={6} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
          <PhoneIcon sx={{ marginRight: 1, marginTop: '-15px' }} />
          <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins', fontSize: '13px', marginTop: '-15px' }}>
            +237 699723793 / 233 47 83 56
          </Typography>
        </Grid>
        {/* Adresse e-mail */}
        <Grid item xs={6} sm={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <MailIcon sx={{ marginRight: 1, marginTop: '-15px' }} />
          <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins', fontSize: '13px', marginTop: '-15px' }}>
            osaveur@yahoo.fr
          </Typography>
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
  )
}

export default TopMenuInfos
