import React from 'react';
import { Box } from '@mui/material';
import AppRoutes from './Routes';


function App() {
  return (
   <Box>
      <AppRoutes/>
   </Box>
  );
}

export default App;
